import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const styles = {
    textDisabled: {
        color: "#dadada",
    },
};

const useStyles = makeStyles(styles);

export default function TextDisabled(props) {
    const classes = useStyles();

    return <div className={classes.textDisabled}>{props.value}</div>;
}