import React from "react";
import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomSelect from "components/CustomSelect/CustomSelect.js";
import Button from "components/CustomButtons/Button.js";
import Check from "@material-ui/icons/Check";
import checkboxAdnRadioStyle from "assets/jss/material-dashboard-react/checkboxAdnRadioStyle.js";

import * as api from "api";
import * as errorUtil from "util/errorUtil"

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0",
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
    },
    checkboxMargin: {
        marginTop: "37px",
    },
};

const useStyles = makeStyles(styles);
const useCbStyles = makeStyles(checkboxAdnRadioStyle);

export default function LocationDialog(props) {
    const classes = useStyles();
    const cbClasses = useCbStyles();

    var { open, onClose, 
         formData
    } = props;

    var pLocation = formData? formData: {};



    var {id, name, address, country, type, city, active, inventory} = pLocation;

    var cbActiveDisabled = false;
    if(inventory) {
        for (const [key, value] of Object.entries(inventory)) {
            if(value.quantity > 0) {
                cbActiveDisabled = true;
                break;
            }
        }
    }

    const [submitDisabled, setSubmitDisabled] = React.useState(false);
    const [fname, setName] = React.useState(name? name: "");
    const [faddress, setAddress] = React.useState(address? address: "");
    const [fcountry, setCountry] = React.useState(country? country: "");
    const [ftype, setType] = React.useState(type? type: "");
    const [fcity, setCity] = React.useState(city? city: "");
    const [factive, setActive] = React.useState(id? active: true);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSubmitDisabled(true);

        const location = {
            name: fname,
            address: faddress,
            city: fcity,
            country: fcountry,
            type: ftype,
            active: factive
        }

        try {
            if (id) {
                location.id = id;
                await api.updateLocation(location);
            } else {
                await api.addLocation(location);
            }    

            close();
        } catch (error) {
            setSubmitDisabled(false);
            errorUtil.handleApiError(error);
        }
    };

    const onCancel = () => {
        close();
    };

    const close = () => {
        onClose();        
    }

    const renderInput = (label, value, valueSetter) => {
        const inputProps = {
            onChange: (event) => {
                valueSetter(event.target.value);
            },
           value: value,
        };
        return (
            <CustomInput
                labelText={label}
                formControlProps={{
                    fullWidth: true,
                }}
                inputProps={inputProps}
            />
        )
    }

    const handleChange = (event) => {
        setType(event.target.value);
      };

    const selectProps = {
        onChange: handleChange,
        value: ftype
    }

    const menuItems = [
        "Warehouse",
        "Sales team",
        "Transport"
    ]

    return (
        <Dialog
            aria-labelledby="simple-dialog-title"
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth={true}
        >
            <DialogTitle>
                {formData ? "Edit location" : "Add new location"}
            </DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={5}>
                            <CustomSelect labelText="Location type" selectProps={selectProps} menuItems={menuItems}/>
                            {renderInput("Location name", fname, setName)}
                            {renderInput("Address", faddress, setAddress)}
                            {renderInput("City", fcity, setCity)}
                            {renderInput("Country", fcountry, setCountry)}
                            <FormControlLabel
                                className={classes.checkboxMargin}
                                disabled={cbActiveDisabled}
                                control={
                                    <Checkbox
                                        id="cbActive"
                                        name="cbActive"
                                        onChange={(event) => {
                                            setActive(event.target.checked);
                                        }}
                                        tabIndex={-1}
                                        checkedIcon={
                                            <Check
                                                className={
                                                    cbClasses.checkedIcon
                                                }
                                            />
                                        }
                                        checked={factive}
                                        icon={
                                            <Check
                                                className={
                                                    cbClasses.uncheckedIcon
                                                }
                                            />
                                        }
                                        classes={{
                                            checked: cbClasses.checked,
                                            root: cbClasses.root,
                                        }}
                                    />
                                }
                                label="Active"
                                labelPlacement="start"
                            />
                        </GridItem>
                    </GridContainer>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button color="primary" type="submit" disabled={submitDisabled}>
                        Save
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}
