function handleApiError(error) {
    var alertMessage = 'An unknown error has occurred.';
    switch(error.message) {
        case 'validation_error':
            alertMessage = 'Request not valid.';
            break;
        case 'permission_denied':
            alertMessage = 'You do not have permission to perform this action.';
            break;
        case 'location_has_inventory':
            alertMessage = 'This location cannot be deactivated because it still has stock.';
            break;
        default:
          // code block
    }

    if(error.details) {
        alertMessage += '\n\n' + error.details;
    }

    alert(alertMessage);
}

export {
    handleApiError
}

// validation_error">Request not valid.</string>
// permission_denied">You do not have permission to perform this action.</string>
// invalid_destination_location">Invalid destination location provided.</string>
// invalid_product">Invalid product provided.</string>
// invalid_source_location">Invalid source location provided.</string>
// not_enough_stock">Not enough stock available.</string>
// invalid_inventory_item">Invalid inventory item.</string>
// invalid_serial">Serial number(s) provided does not exist in source inventory.</string>
// transfer_not_found">Unknown transfer.</string>
// transfer_status_not_open">This transfer has already been completed or cancelled.</string>
// serial_already_exists">Serial number(s) already exist in inventory.</string>
// unknown">An unknown error has occurred.</string>