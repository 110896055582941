import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import * as api from "api";
import Button from "components/CustomButtons/Button.js";
import RoleDialog from "views/Role/RoleDialog.js";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import * as errorUtil from "util/errorUtil"

// @material-ui/icons
import Edit from "@material-ui/icons/Edit";

import taskStyles from "assets/jss/material-dashboard-react/components/tasksStyle.js";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
};

const useStyles = makeStyles(styles);
const useTaskStyles = makeStyles(taskStyles);

function RoleTable(props) {
    const classes = useStyles();

    return (
        <Card>
            <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>All Roles</h4>
                <p className={classes.cardCategoryWhite}>
                    Manage roles
                </p>
            </CardHeader>
            <CardBody>
                <Table
                    tableHeaderColor="primary"
                    tableHead={["Name", "Actions"]}
                    tableData={props.tableData}
                />
            </CardBody>
        </Card>
    );
}

function Actions(props) {
    const classes = useTaskStyles();
    const { onEditClick } = props;

    return (
        <div>
            <Tooltip id="tooltip-top" title="Edit" placement="top">
                <IconButton
                    aria-label="Edit"
                    className={classes.tableActionButton}
                    onClick={onEditClick}
                >
                    <Edit
                        className={
                            classes.tableActionButtonIcon + " " + classes.edit
                        }
                    />
                </IconButton>
            </Tooltip>
        </div>
    );
}

export default class RoleList extends React.Component {
    constructor() {
        super();
        this.state = {
            open: false,
        };
    }

    async componentDidMount() {
        try {
            const rolesAndPermissions = await api.getAllRolesAndPermissions();
    
            this.setState({ 
                data: rolesAndPermissions.roles,
                permissions: rolesAndPermissions.permissions
            });
        } catch (error) {
            this.setState({ 
                data: [],
                permissions: []
            });
            errorUtil.handleApiError(error);
        }
    }

    handleClickOpen = () => {
        this.setState({
            open: true,
        });
    };

    onDialogClose = async () => {
        try {
            
            const rolesAndPermissions = await api.getAllRolesAndPermissions();
            this.setState({
                data: rolesAndPermissions.roles,
                permissions: rolesAndPermissions.permissions,
                open: false,
                selectedObject: null,
            });
        } catch (error) {
            this.setState({
                data: [],
                permissions: [],
                open: false,
                selectedObject: null,
            });
            errorUtil.handleApiError(error);
        }
    };

    openEditDialog(selectedObject) {
        console.log(JSON.stringify(selectedObject));
        this.setState({
            selectedObject: selectedObject,
            open: true,
        });
    }

    renderTable() {
        if (this.state && this.state.data) {
            const tableData = this.state.data.map((role) => {
                return [
                    role.name,
                    <Actions
                        onEditClick={() => {
                            this.openEditDialog(role);
                        }}
                    />,
                ];
            });
            return <RoleTable tableData={tableData} />;
        } else {
            return <p>Loading...</p>;
        }
    }

    renderDialog() {
        const {open, selectedObject, permissions} = this.state;

        if (open) {
            return (
                <RoleDialog
                    open={open}
                    onClose={this.onDialogClose}
                    formData={selectedObject}
                    permissionList={permissions}
                />
            );
        } else {
            return "";
        }
    }

    render() {
        return (
            <div>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleClickOpen}
                >
                    New role
                </Button>
                {this.renderDialog()}

                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        {this.renderTable()}
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}
