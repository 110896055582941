import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CustomSelect from "components/CustomSelect/CustomSelect.js";
import LocationEventsDialog from 'views/Dashboard/LocationEventsDialog.js';
import LocationInventoryDialog from 'views/Dashboard/LocationInventoryDialog.js';
import * as api from "api";
import * as errorUtil from "util/errorUtil"

const ALL_COUNTRIES = 'All Countries';
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#ffffff",
        },
    },
    cardTitleWhite: {
        color: "#ffffff",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    textButton: {
        '&:hover': {
            color: "#F47B16",
        },
        background: "none !important",
        border: "none",
        padding: "0 !important",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        fontWeight: "bold",
        cursor: "pointer",
        
    },
    textButton2: {
        '&:hover': {
            color: "#F47B16",
        },
        color: "rgba(0, 0, 0, 0.87)",
        background: "none !important",
        border: "none",
        padding: "0 !important",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        fontWeight: "300",
        cursor: "pointer",
        
    },
};

const useStyles = makeStyles(styles);

function OverviewTable(props) {
    const classes = useStyles();

    return (
        <Card>
            <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Stock Overview</h4>
                <p className={classes.cardCategoryWhite}>
                    The complete overview of stock in the ACE inventory
                </p>
                
            </CardHeader>
            <CardBody>
                <CustomSelect labelText="Country filter" selectProps={props.selectProps} menuItems={props.menuItems}/>
                <Table
                    tableHeaderColor="primary"
                    tableHead={props.tableHeader}
                    tableData={props.tableData}
                />
            </CardBody>
        </Card>
    );
}

function TextButton(props) {
    const classes = useStyles();

    return (<button className={classes[props.className]} onClick={(event) => {
        props.onClick(props.onClickParam);
    }}>{props.value}</button>)
}
export default class StockOverview extends React.Component {
    constructor() {
        super();
        this.state = {
            open: false,
            openInventory: false,
            selectedObject: null,
            selectedCountry: ALL_COUNTRIES,
        };
    }

    async componentDidMount() {
        try {
            const stockOverviewData = await api.getStockOverview();
            const products = stockOverviewData.products;
            const locations = stockOverviewData.locations;

            const countries = stockOverviewData.countries;
            countries.unshift(ALL_COUNTRIES);

            this.filterInventory(products, locations, ALL_COUNTRIES);
            this.setState({ 
                products: products,
                locations: locations,
                countries: countries,
            });
        } catch (error) {
            errorUtil.handleApiError(error);  
        }
    }

    handleChange = (event) => {
        this.filterInventory(this.state.products, this.state.locations, event.target.value);
    };

    filterInventory(products, locations, country) {
        const totals = {};

        const filteredCountries = [];
    
        console.log("locations: " + JSON.stringify(locations));
        locations.forEach(location => {
            if(!location.active) {
                return;
            }

            if(!country || country === ALL_COUNTRIES || location.country === country) {
                filteredCountries.push(location);

                products.forEach(product => {
                    var value = location.inventory[product.id];
                    if(value) {

                        if(!totals[product.id]) {
                            totals[product.id] = 0;
                        }

                        totals[product.id] += value.quantity;
                    }
                });
            }
        });

        filteredCountries.sort(function(a, b) {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
          
            // names must be equal
            return 0;
        });

        const filteredProducts = [];

        products.forEach(product => {
            if(totals[product.id]) {
                filteredProducts.push(product);
            }
        });

        const tableData = [];
        
        filteredCountries.forEach(location => {
            if(!country || country === ALL_COUNTRIES || location.country === country) {
                const row = [<TextButton className="textButton" value={location.name} onClickParam={location} onClick={this.openDialog}/>];
                filteredProducts.forEach(product => {
                    var value = location.inventory[product.id];

                    if(value) {
                        //totals[product.id] += value.quantity;
                        if(product.isSerialised) {
                            row.push(<TextButton className="textButton2" value={value.quantity} onClickParam={{location:location, product:product}} onClick={this.openInventoryDialog}/>);
                        } else {
                            row.push(value.quantity);
                        }
                        
                    } else {
                        row.push('0');
                    }
                });
                tableData.push(row);
            }
        });

        const tableHeader = ['Location'];
        filteredProducts.forEach(product => {
            tableHeader.push(product.name + ' (' + totals[product.id] + ')');
        });

        this.setState({ 
            tableData: tableData,
            tableHeader: tableHeader,
            selectedCountry: country,
         });
    }

    onDialogClose = async () => {
        this.setState({
            open: false,
            openInventory: false,
            selectedObject: null,
        });
    };

    openDialog = (location) => {
        this.setState({
            selectedObject: location,
            open: true,
        });
        return false;
    };

    openInventoryDialog = (selectedObject) => {
        this.setState({
            selectedObject: selectedObject,
            openInventory: true,
        });
    };

    renderDialog() {
        const {open, selectedObject} = this.state;

        if (open) {
            return (
                <LocationEventsDialog
                    open={open}
                    onClose={this.onDialogClose}
                    location={selectedObject}
                />
            );
        } else {
            return "";
        }
    }

    renderInventoryDialog() {
        const {openInventory, selectedObject} = this.state;

        if (openInventory) {
            return (
                <LocationInventoryDialog
                    open={openInventory}
                    onClose={this.onDialogClose}
                    location={selectedObject.location}
                    product={selectedObject.product}
                />
            );
        } else {
            return "";
        }
    }

    renderTable() {
        const selectProps = {
            onChange: this.handleChange,
            value: this.state.selectedCountry
        }

        const menuItems = this.state.countries? this.state.countries: [];

        if (this.state && this.state.tableData) {
            return <OverviewTable selectProps={selectProps} menuItems={menuItems} tableHeader={this.state.tableHeader} tableData={this.state.tableData} />;
        } else {
            return <p>Loading...</p>;
        }
    }


    render() {
        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        {this.renderTable()}
                    </GridItem>
                </GridContainer>
                {this.renderDialog()}
                {this.renderInventoryDialog()}
            </div>
        );
    }
}
