import React from "react";
import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Check from "@material-ui/icons/Check";
import Button from "components/CustomButtons/Button.js";
import checkboxAdnRadioStyle from "assets/jss/material-dashboard-react/checkboxAdnRadioStyle.js";

import * as api from "api";

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0",
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
    },
    checkboxMargin: {
        marginTop: "37px",
    },
};

const useStyles = makeStyles(styles);
const useCbStyles = makeStyles(checkboxAdnRadioStyle);

export default function RoleDialog(props) {
    const classes = useStyles();
    const cbClasses = useCbStyles();

    var { open, onClose, formData, permissionList } = props;

    var pRole = formData ? formData : {};

    var { id, name, permissions } = pRole;

    const [submitDisabled, setSubmitDisabled] = React.useState(false);
    const [fname, setName] = React.useState(name ? name : "");
    const [fpermissionsOwned, setPermissionsOwned] = React.useState(id? permissions: []);


    const handleSubmit = async (event) => {
        event.preventDefault();
        setSubmitDisabled(true);

        const role = {
            name: fname,
            permissions: fpermissionsOwned,
        };

        try {
            if (id) {
                role.id = id;
                console.log("updating role", role);
                await api.updateRole(role);
            } else {
                console.log("adding role", role);
                await api.addRole(role);
            }

            close();
        } catch (error) {
             setSubmitDisabled(false);       
        }
    };

    const onCancel = () => {
        close();
    };

    const close = () => {
        onClose();
    };

    const renderInput = (label, value, valueSetter) => {
        const inputProps = {
            onChange: (event) => {
                valueSetter(event.target.value);
            },
            value: value,
        };
        return (
            <CustomInput
                labelText={label}
                formControlProps={{
                    fullWidth: true,
                }}
                inputProps={inputProps}
            />
        );
    };

    const renderPermissionCheckboxes = () => {
        if(!permissionList) {
            return;
        }

        return permissionList.map((permission, index) => {
            return(<div key={index}><FormControlLabel
                className={classes.checkboxMargin}
                control={
                    <Checkbox
                        id={permission}
                        name={permission}
                        onChange={(event) => {
                            if(event.target.checked) {
                                if(!fpermissionsOwned.includes(permission)){
                                    const newPermissions = [...fpermissionsOwned, permission];
                                    setPermissionsOwned(newPermissions);
                                }
                            } else {
                                if(fpermissionsOwned.includes(permission)){
                                    console.log('removing permission:', permission);
                                    console.log('old permission array:',fpermissionsOwned);
                                   
                                    const newPermissions = [];
                                    fpermissionsOwned.forEach(p => {
                                        if(p !== permission) {
                                            newPermissions.push(p);
                                        }
                                    })
                                    console.log('new permission array:',newPermissions);
                                    setPermissionsOwned(newPermissions);
                                }
                            }
                        }}
                        tabIndex={-1}
                        checkedIcon={
                            <Check
                                className={
                                    cbClasses.checkedIcon
                                }
                            />
                        }
                        checked={fpermissionsOwned.includes(permission)}
                        icon={
                            <Check
                                className={
                                    cbClasses.uncheckedIcon
                                }
                            />
                        }
                        classes={{
                            checked: cbClasses.checked,
                            root: cbClasses.root,
                        }}
                    />
                }
                label={permission}
                labelPlacement="end"
            /></div>)
        });
    };


    return (
        <Dialog
            aria-labelledby="simple-dialog-title"
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth={true}
        >
            <DialogTitle>{formData ? "Edit role" : "Add new role"}</DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <GridContainer>
                        <GridItem xs={12} sm={4}>
                            {renderInput("Name", fname, setName)}
                        </GridItem>
                        <GridItem xs={12} sm={4}>
                            Permissions:
                            {renderPermissionCheckboxes()}
                        </GridItem>
                    </GridContainer>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button color="primary" type="submit" disabled={submitDisabled}>
                        Save
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}
