import React from "react";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { makeStyles } from '@material-ui/core/styles';

import {
    primaryColor,
    dangerColor,
    successColor,
    grayColor,
    defaultFont
  } from "assets/jss/material-dashboard-react.js";
  
  const customInputStyle = {
    disabled: {
      "&:before": {
        backgroundColor: "transparent !important"
      }
    },
    underline: {
      "&:hover:not($disabled):before,&:before": {
        borderColor: grayColor[4] + " !important",
        borderWidth: "1px !important"
      },
      "&:after": {
        borderColor: primaryColor[0]
      }
    },
    underlineError: {
      "&:after": {
        borderColor: dangerColor[0]
      }
    },
    underlineSuccess: {
      "&:after": {
        borderColor: successColor[0]
      }
    },
    labelRoot: {
      ...defaultFont,
      color: grayColor[3] + " !important",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "1.42857",
      letterSpacing: "unset"
    },
    labelRootError: {
      color: dangerColor[0]
    },
    labelRootSuccess: {
      color: successColor[0]
    },
    feedback: {
      position: "absolute",
      top: "18px",
      right: "0",
      zIndex: "2",
      display: "block",
      width: "24px",
      height: "24px",
      textAlign: "center",
      pointerEvents: "none"
    },
    marginTop: {
      marginTop: "16px"
    },
    formControl: {
      paddingBottom: "10px",
      margin: "27px 0 0 0", 
      position: "relative",
      verticalAlign: "unset",
      minWidth: "250px"
    }
  };

const useStyles = makeStyles(customInputStyle);

export default function CustomSelect(props) {
    const classes = useStyles();

    const {selectProps, labelText, menuItems} = props;

    const renderMenuItems = (menuItems) => {
        return menuItems.map(element => {
            return (
                <MenuItem key={element} value={element}>{element}</MenuItem>
            )
        });
        
    }

    return (                            
        <FormControl className={classes.formControl}>
            <InputLabel 
                id="demo-simple-select-label"
                className={classes.labelRoot}
                >
                {labelText}
            </InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                className={classes.underline}
                {...selectProps}
                >
                {renderMenuItems(menuItems)}
            </Select>
        </FormControl>
    )
}