import React from "react";
import {firebaseAuth} from "api";
import "./FirebaseUI.css";
// Initialize Firebase

//var firebase = require('firebase');
var firebaseui = require('firebaseui');

var ui = new firebaseui.auth.AuthUI(firebaseAuth);


export default class FirebaseUI extends React.Component {

    componentDidMount() {
        
        ui.start('#firebaseui-auth-container', this.props.uiConfig);
    }

    render() { 
        return <div id="firebaseui-auth-container"></div>;
    }
}
