import "./SignInScreen.css";
import React from "react";

//import Login from './containers/login';


//import MainScreen from "./MainScreen";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import FirebaseUI from "views/Signin/FirebaseUI"
import { createBrowserHistory } from "history";

import Admin from "layouts/Admin.js";


var firebase = require('firebase').default;
const hist = createBrowserHistory();

class SignInScreen extends React.Component {
    // The component's Local state.
    state = {
        isSignedIn: false, // Local signed-in state.
    };

    // Configure FirebaseUI.
    uiConfig = {
        // Popup signin flow rather than redirect flow.
        signInFlow: "popup",
        signInOptions: [
            {
                provider:firebase.auth.EmailAuthProvider.PROVIDER_ID,
                disableSignUp: {
                    status: true
                }
            }],
        callbacks: {
            // Avoid redirects after sign-in.
            signInSuccessWithAuthResult: () => false,
        },
    };

    // Listen to the Firebase Auth state and set the local state.
    componentDidMount() {
        this.unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) =>
                this.setState({ isSignedIn: !!user })
            );
    }

    // Make sure we un-register Firebase observers when the component unmounts.
    componentWillUnmount() {
        this.unregisterAuthObserver();
    }

    render() {
        if (!this.state.isSignedIn) {
            return (
                <div>
                    <h1>InvMan Admin</h1>
                    <p>Please sign-in:</p>
                    <FirebaseUI uiConfig={this.uiConfig}/>
                </div>
            );
        } else {
            return (
                <Router history={hist}>
                    <Switch>
                        <Route path="/admin" component={Admin} />
                        <Redirect from="/" to="/admin/dashboard" />
                    </Switch>
                </Router>
            );
        }
    }
}

export default SignInScreen;
