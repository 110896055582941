import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import TextDisabled from "components/TextDisabled.js";
import * as api from "api";
import Button from "components/CustomButtons/Button.js";
import UserDialog from "views/User/UserDialog.js";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import * as errorUtil from "util/errorUtil"

// @material-ui/icons
import Edit from "@material-ui/icons/Edit";

import taskStyles from "assets/jss/material-dashboard-react/components/tasksStyle.js";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    textDisabled: {
        color: "#dadada",
    },
};

const useStyles = makeStyles(styles);
const useTaskStyles = makeStyles(taskStyles);

function UserTable(props) {
    const classes = useStyles();

    return (
        <Card>
            <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>All users</h4>
                <p className={classes.cardCategoryWhite}>
                    Manage users
                </p>
            </CardHeader>
            <CardBody>
                <Table
                    tableHeaderColor="primary"
                    tableHead={["Email", "First Name", "Last Name", "Role", "UID", "Actions"]}
                    tableData={props.tableData}
                />
            </CardBody>
        </Card>
    );
}

function Actions(props) {
    const classes = useTaskStyles();
    const { onEditClick } = props;

    return (
        <div>
            <Tooltip id="tooltip-top" title="Edit" placement="top">
                <IconButton
                    aria-label="Edit"
                    className={classes.tableActionButton}
                    onClick={onEditClick}
                >
                    <Edit
                        className={
                            classes.tableActionButtonIcon + " " + classes.edit
                        }
                    />
                </IconButton>
            </Tooltip>
        </div>
    );
}
export default class UserList extends React.Component {
    constructor() {
        super();
        this.state = {
            open: false,
        };
    }

    async componentDidMount() {
        try {
            
            const {users, locations, roles} = await api.getUsersOverview();

            const rolesMap = {};
            roles.forEach(role => {
                rolesMap[role.id] = role.name;
            });

            this.setState({ 
                data: users,
                locations: locations,
                roles: roles,
                rolesMap: rolesMap,
            });

        } catch (error) {
            this.setState({ 
                data: [],
                locations: [],
                roles: [],
                rolesMap: {},
            });
            
            errorUtil.handleApiError(error);
        }
    }

    handleClickOpen = () => {
        this.setState({
            open: true,
        });
    };

    onDialogClose = async () => {
        try {
            const users = await api.getAllUsers();
            this.setState({
                data: users,
                open: false,
                selectedObject: null,
            });
        } catch (error) {
            this.setState({
                data: [],
                open: false,
                selectedObject: null,
            });

            errorUtil.handleApiError(error);
        }
    };

    openEditDialog(selectedObject) {
        console.log(JSON.stringify(selectedObject));
        this.setState({
            selectedObject: selectedObject,
            open: true,
        });
    }

    renderTable() {
        if (this.state && this.state.data) {
            const tableData = this.state.data.map((user) => {
                var roleName = "";
                if(user.customClaims.admin) {
                    roleName = "Administrator";
                } else if(!!user.customClaims.roleId) {
                    roleName = this.state.rolesMap[user.customClaims.roleId];
                }
                return [
                    user.disabled?<TextDisabled value={user.email} />: user.email,
                    user.disabled?<TextDisabled value={user.firstName} />: user.firstName,
                    user.disabled?<TextDisabled value={user.lastName} />: user.lastName,
                    user.disabled?<TextDisabled value={roleName} />: roleName,
                    user.disabled?<TextDisabled value={user.id} />: user.id,
                    <Actions
                        onEditClick={() => {
                            this.openEditDialog(user);
                        }}
                    />,
                ];
            });
            return <UserTable tableData={tableData} />;
        } else {
            return <p>Loading...</p>;
        }
    }

    renderDialog() {
        const {open, selectedObject, locations, roles} = this.state;

        const countries = ["Cambodia","Kenya","Lesotho","Mozambique","Netherlands","Uganda"];

        if (open) {
            return (
                <UserDialog
                    open={open}
                    onClose={this.onDialogClose}
                    formData={selectedObject}
                    locations={locations}
                    roles={roles}
                    countries={countries}
                />
            );
        } else {
            return "";
        }
    }

    render() {
        return (
            <div>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleClickOpen}
                >
                    New user
                </Button>
                {this.renderDialog()}

                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        {this.renderTable()}
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}
