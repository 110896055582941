import React from "react";
import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Check from "@material-ui/icons/Check";
import checkboxAdnRadioStyle from "assets/jss/material-dashboard-react/checkboxAdnRadioStyle.js";

import * as api from "api";
import * as errorUtil from "util/errorUtil"

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0",
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
    },
    checkboxMargin: {
        marginTop: "37px",
    },
};

const useStyles = makeStyles(styles);
const useCbStyles = makeStyles(checkboxAdnRadioStyle);

export default function ProductDialog(props) {
    const classes = useStyles();
    const cbClasses = useCbStyles();
    var { open, onClose, 
         pSelected 
    } = props;

    if(!pSelected) {
        pSelected = {};
    }
    const [submitDisabled, setSubmitDisabled] = React.useState(false);
    const [productName, setProductName] = React.useState(pSelected.name? pSelected.name: "");
    const [isSerialised, setSerialised] = React.useState(pSelected.isSerialised? pSelected.isSerialised: false);
    const [productCategory, setProductCategory] = React.useState(pSelected.category? pSelected.category: "");
    const [productWeight, setProductWeight] = React.useState(pSelected.weightInGrams? pSelected.weightInGrams: "");

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSubmitDisabled(true);

        const product = {
            name: productName,
            isSerialised: isSerialised,
            category: productCategory
        }

        if(productWeight !== "" && !isNaN(productWeight))
            product.weightInGrams = parseInt(productWeight);

        try {
            if (pSelected.id) {
                product.id = pSelected.id;
                await api.updateProduct(product);
            } else {
                await api.addProduct(product);
            }
    
            close();    
        } catch (error) {
            setSubmitDisabled(false);
            errorUtil.handleApiError(error);
        }
        
    };

    const onCancel = () => {
        close();
    };

    const close = () => {
        onClose();        
    }

    const inputPropsName = {
        onChange: (event) => {
            setProductName(event.target.value);
        },
       value: productName,
    };
    const inputPropsCategory = {
        onChange: (event) => {
            setProductCategory(event.target.value);
        },
       value: productCategory,
    };
    const inputPropsWeight = {
        onChange: (event) => {
            setProductWeight(event.target.value);
        },
       value: productWeight,
    };

    return (
        <Dialog
            aria-labelledby="simple-dialog-title"
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth={true}
        >
            <DialogTitle>
                {pSelected.id ? "Edit product" : "Add new product"}
            </DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={5}>
                            <CustomInput
                                labelText="Product name"
                                id="name"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={inputPropsName}
                            />
                            <CustomInput
                                labelText="Category"
                                id="category"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={inputPropsCategory}
                            />
                            <CustomInput
                                labelText="Weight (grams)"
                                id="weight"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={inputPropsWeight}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                            <FormControlLabel
                                className={classes.checkboxMargin}
                                control={
                                    <Checkbox
                                        id="isSerialised"
                                        name="isSerialised"
                                        onChange={(event) => {
                                            setSerialised(event.target.checked);
                                        }}
                                        tabIndex={-1}
                                        checkedIcon={
                                            <Check
                                                className={
                                                    cbClasses.checkedIcon
                                                }
                                            />
                                        }
                                        checked={isSerialised}
                                        icon={
                                            <Check
                                                className={
                                                    cbClasses.uncheckedIcon
                                                }
                                            />
                                        }
                                        classes={{
                                            checked: cbClasses.checked,
                                            root: cbClasses.root,
                                        }}
                                    />
                                }
                                label="Is serialised"
                                labelPlacement="start"
                            />
                        </GridItem>
                    </GridContainer>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button color="primary" type="submit" disabled={submitDisabled}>
                        Save
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}
