import React from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@material-ui/core";

import { CSVLink } from "react-csv";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import KeyValueSelect from "components/CustomSelect/KeyValueSelect.js";

import * as api from "api";
import * as errorUtil from "util/errorUtil"

function formatSerialNumbers(serialNumbers) {
    if(!serialNumbers) {
        return '';
    }

    return (<div>
        {
        serialNumbers.map( (val, index) => {
            return (<div key={index}>{val}</div>);
        })
        }
    </div>)
}

function EventsTable(props) {
    return (
        <Card>
            <CardBody>
                <Table
                    tableHeaderColor="primary"
                    tableHead={props.tableHeader}
                    tableData={props.tableData}
                />
            </CardBody>
        </Card>
    );
}

export default function LocationEventsDialog(props) {
    var { open, onClose, 
         location
    } = props;

    const tableHeader = ["Date", "Type", "Product", "Serial Numbers", "Source", "Destination", "Quantity", "New Balance (Source)", "New Balance (Dest)"];
    const [tableData, setTableData] = React.useState('');
    const [selectedPeriod, setSelectedPeriod] = React.useState(1);
    const [csvValues, setCsvValues] = React.useState([]);

    // const csvData = [];

    // if(csvValues) {
    //     csvValues.forEach( el => {
    //         csvData.push(el);
    //     });
    // }

    React.useEffect(() => {
        if(!tableData) {
            retrieveData(1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const retrieveData = async (days) => {
        const now = new Date();
        const startDateInMs = now.getTime() - (days * 24 * 60 * 60 * 1000);
        // const startDateInMs = 1609455600000;
        console.log('timeInMs', startDateInMs);

        var result = [];
        try {
            result = await api.getLocationEvents(location.id, startDateInMs);
        } catch (error) {
            errorUtil.handleApiError(error);
        }
        
        const processedResult = [];
        const processedResult2 = [];

        result.forEach(event => {
            const eventTime = new Date(event.date);
            const sourceNewBalance = event.sourceNewBalance? event.sourceNewBalance: '';
            const destinationNewBalance = event.destinationNewBalance? event.destinationNewBalance: '';
            const processed = [
                eventTime.toLocaleString(),
                event.type,
                event.productName,
                formatSerialNumbers(event.serialNumbers),
                event.inventoryStorageLocationSourceName,
                event.inventoryStorageLocationDestinationName,
                event.quantity,
                sourceNewBalance,
                destinationNewBalance
            ];

            const processed2 = [
                eventTime.toLocaleString(),
                event.type,
                event.productName,
                event.serialNumbers.join(' '),
                event.inventoryStorageLocationSourceName,
                event.inventoryStorageLocationDestinationName,
                event.quantity,
                sourceNewBalance,
                destinationNewBalance
            ];

            processedResult.push(processed);
            processedResult2.push(processed2);
        });

        setTableData(processedResult);
        setCsvValues(processedResult2);
    }

     const close = () => {
        onClose();        
    }

    const selectProps = {
        onChange: (event) => { 
            retrieveData(event.target.value)
            setSelectedPeriod(event.target.value);
        },
        value: selectedPeriod
    }

    const menuItems = [
        {
            id: 1,
            name: "Last 24 hours"
        },
        {
            id: 2,
            name: "Last 48 hours"
        },
        {
            id: 7,
            name: "Last 7 days"
        },
        {
            id: 30,
            name: "Last 30 days"
        },
        {
            id: 90,
            name: "Last 90 days"
        },
    ];

    const prettyLink  = {
        backgroundColor: '#8dc63f',
        fontSize: 14,
        fontWeight: 500,
        height: 52,
        padding: '0 48px',
        borderRadius: 5,
        color: '#fff'
      };

    return (
        <Dialog
            aria-labelledby="simple-dialog-title"
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth={true}
        >
        <DialogTitle>
            { location.name + " Events"}
        </DialogTitle>
            <DialogContent>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <KeyValueSelect labelText="Event period" selectProps={selectProps} keyValueMap={menuItems}/>
                        {tableData? <div><br/><CSVLink style={prettyLink} filename={'Events ' + location.name} headers={tableHeader} data={csvValues}>Export CSV</CSVLink><EventsTable tableHeader={tableHeader} tableData={tableData} /></div>: <div><p>Loading...</p></div>}
                    </GridItem>
                </GridContainer>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={close}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}
