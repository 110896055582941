import React from "react";
import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    InputLabel,
    Select,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Check from "@material-ui/icons/Check";
import Button from "components/CustomButtons/Button.js";
import checkboxAdnRadioStyle from "assets/jss/material-dashboard-react/checkboxAdnRadioStyle.js";
import KeyValueSelect from "components/CustomSelect/KeyValueSelect.js";

import * as api from "api";
import _ from "lodash";
import * as errorUtil from "util/errorUtil"
import config from "config";

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0",
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
    },
    checkboxMargin: {
        marginTop: "37px",
    },
};

const useStyles = makeStyles(styles);
const useCbStyles = makeStyles(checkboxAdnRadioStyle);

export default function UserDialog(props) {
    const classes = useStyles();
    const cbClasses = useCbStyles();

    var { open, onClose, formData, locations, roles, countries } = props;

    console.log('USER DIALOG ROLES:', roles)

    var pUser = formData ? formData : {};

    var { id, email, disabled, customClaims, firstName, lastName } = pUser;

    var locationsSelection;
    var locationsOwnedSelection;

    var countriesSelection;
    var countriesOwnedSelection;

    var locationsCopy = locations.map((el) => el);

    var locationsOwned = [];
    var roleId = "";
    var countriesOwned = [];
    if(id) {
        customClaims.locationsOwned.forEach((id) => {
            const locFound = locationsCopy.find((loc) => {
                return loc.id === id;
            })

            if(locFound) locationsOwned.push(locFound);
        });
        locationsCopy = _.xor(locationsOwned, locationsCopy);

        if(customClaims.roleId) {
            roleId = customClaims.roleId;
        }

        ;

        if(customClaims.countriesFilter){
            countriesOwned = customClaims.countriesFilter;
            countries = _.xor(countriesOwned, countries);
        }
            
    }

    

    
    const [submitDisabled, setSubmitDisabled] = React.useState(false);
    const [flocations, setLocations] = React.useState(locationsCopy);
    const [femail, setEmail] = React.useState(email ? email : "");
    const [ffirstName, setFirstName] = React.useState(firstName ? firstName : "");
    const [flastName, setLastName] = React.useState(lastName ? lastName : "");
    const [fdisabled, setDisabled] = React.useState(disabled ? disabled : false);
    const [fpassword, setPassword] = React.useState("");
    const [fadmin, setAdmin] = React.useState(!!id? !!customClaims.admin: false);
    const [froleId, setRoleId] = React.useState(roleId);
    const [flocationsOwned, setLocationsOwned] = React.useState(!!id? locationsOwned: []);
    const [fcountries, setCountries] = React.useState(countries);

    const [fcountriesOwned, setCountriesOwned] = React.useState(countriesOwned);

    const onSelectChange = (event) => {
        setRoleId(event.target.value);
    };

    const selectProps = {
        onChange: onSelectChange,
        value: froleId
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSubmitDisabled(true);

        const user = {
            email: femail,
            firstName: ffirstName,
            lastName: flastName,
            password: fpassword,
            disabled: fdisabled,
            customClaims: {
                admin: fadmin,
                roleId: froleId,
                locationsOwned: flocationsOwned.map((loc) => loc.id),
                countriesFilter: fcountriesOwned
            },
        };

        try {
            if (id) {
                user.id = id;
                console.log("updating user", user);
                await api.updateUser(user);
            } else {
                console.log("adding user", user);
                await api.addUser(user);

                if(config.env !== 'local') {
                    console.log("send reset to ", user.email);
                    await api.sendPasswordResetEmail(user.email);
                }
            }

            close();
        } catch (error) {
             setSubmitDisabled(false); 
             errorUtil.handleApiError(error);      
        }
    };

    const onCancel = () => {
        close();
    };

    const close = () => {
        onClose();
    };

    const renderInput = (label, value, valueSetter) => {
        const inputProps = {
            onChange: (event) => {
                valueSetter(event.target.value);
            },
            value: value,
        };
        return (
            <CustomInput
                labelText={label}
                formControlProps={{
                    fullWidth: true,
                }}
                inputProps={inputProps}
            />
        );
    };

    const handleChangeLocations = (event) => {
        const { options } = event.target;
        
        const values = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
            if (options[i].selected) {
                values.push(options[i].value);
            }
        }

        const selectedLocations = values.map((id) => {
            return flocations.find((loc) => {
                return loc.id === id;
            })
        })
        locationsSelection = selectedLocations;
      };    

      const handleChangeLocationsOwned = (event) => {
        const { options } = event.target;
        
        const values = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
            if (options[i].selected) {
                values.push(options[i].value);
            }
        }

        const selectedLocations = values.map((id) => {
            return flocationsOwned.find((loc) => {
                return loc.id === id;
            })
        })
        locationsOwnedSelection = selectedLocations;
      };  

      const handleChangeCountries = (event) => {
        const { options } = event.target;
        
        const values = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
            if (options[i].selected) {
                values.push(options[i].value);
            }
        }

        countriesSelection = values;
      };

      const handleChangeCountriesOwned = (event) => {
        const { options } = event.target;
        
        const values = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
            if (options[i].selected) {
                values.push(options[i].value);
            }
        }

        countriesOwnedSelection = values;
      };
      
      const handleToLeftClick = () => {
        if(locationsSelection) {
            setLocationsOwned(_.union(locationsSelection, flocationsOwned));
            setLocations( _.xor(locationsSelection, flocations));
            locationsSelection = null;
        }
      };

      const handleToRightClick = () => {
        if(locationsOwnedSelection) {
            setLocations(_.union(locationsOwnedSelection, flocations));
            setLocationsOwned( _.xor(locationsOwnedSelection, flocationsOwned));
            locationsOwnedSelection = null;
        }
      };
      
      const handleToLeftClickCountries = () => {
        if(countriesSelection) {
            setCountriesOwned(_.union(countriesSelection, fcountriesOwned));
            setCountries( _.xor(countriesSelection, fcountries));
            countriesSelection = null;
        }
      };

      const handleToRightClickCountries = () => {
        if(countriesOwnedSelection) {
            setCountries(_.union(countriesOwnedSelection, fcountries));
            setCountriesOwned( _.xor(countriesOwnedSelection, fcountriesOwned));
            countriesOwnedSelection = null;
        }
      };

    return (
        <Dialog
            aria-labelledby="simple-dialog-title"
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth={true}
        >
            <DialogTitle>{formData ? "Edit user" : "Add new user"}</DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <GridContainer>
                        <GridItem xs={4}>
                            {renderInput("First Name", ffirstName, setFirstName)}
                            {renderInput("Last Name", flastName, setLastName)}
                            {renderInput("Email", femail, setEmail)}
                            {renderInput("Password", fpassword, setPassword)}
                            <FormControlLabel
                                className={classes.checkboxMargin}
                                control={
                                    <Checkbox
                                        id="cbAdmin"
                                        name="cbAdmin"
                                        onChange={(event) => {
                                            setAdmin(event.target.checked);
                                        }}
                                        tabIndex={-1}
                                        checkedIcon={
                                            <Check
                                                className={
                                                    cbClasses.checkedIcon
                                                }
                                            />
                                        }
                                        checked={fadmin}
                                        icon={
                                            <Check
                                                className={
                                                    cbClasses.uncheckedIcon
                                                }
                                            />
                                        }
                                        classes={{
                                            checked: cbClasses.checked,
                                            root: cbClasses.root,
                                        }}
                                    />
                                }
                                label="Is admin"
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                className={classes.checkboxMargin}
                                control={
                                    <Checkbox
                                        id="cbActive"
                                        name="cbActive"
                                        onChange={(event) => {
                                            setDisabled(!event.target.checked);
                                        }}
                                        tabIndex={-1}
                                        checkedIcon={
                                            <Check
                                                className={
                                                    cbClasses.checkedIcon
                                                }
                                            />
                                        }
                                        checked={!fdisabled}
                                        icon={
                                            <Check
                                                className={
                                                    cbClasses.uncheckedIcon
                                                }
                                            />
                                        }
                                        classes={{
                                            checked: cbClasses.checked,
                                            root: cbClasses.root,
                                        }}
                                    />
                                }
                                label="Active"
                                labelPlacement="start"
                            />
                            <KeyValueSelect labelText="Select role" selectProps={selectProps} keyValueMap={roles}/>
                        </GridItem>
                            <GridItem xs={3}>
                                <FormControl className={classes.formControl} style={{minWidth: 170}}>
                                    <InputLabel
                                        shrink
                                        htmlFor="select-multiple-native"
                                    >
                                        Locations Owned
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        multiple
                                        native
                                        onChange={handleChangeLocationsOwned}
                                        inputProps={{
                                            id: "select-multiple-native",
                                        }}
                                    >
                                        {flocationsOwned.map((location) => (
                                            <option
                                                key={location.id}
                                                value={location.id}
                                            >
                                                {location.name}
                                            </option>
                                        ))}
                                    </Select>
                                </FormControl>


                                <FormControl className={classes.formControl} style={{minWidth: 170}}>
                                    <InputLabel
                                        shrink
                                        htmlFor="select-multiple-native"
                                    >
                                        My countries
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        multiple
                                        native
                                        onChange={handleChangeCountriesOwned}
                                        inputProps={{
                                            id: "select-multiple-native",
                                        }}
                                    >
                                        {fcountriesOwned.map((country) => (
                                            <option
                                                key={country}
                                                value={country}
                                            >
                                                {country}
                                            </option>
                                        ))}
                                    </Select>
                                </FormControl>
                            </GridItem>
                            <GridItem xs={2}>
                                <Button color="primary" onClick={handleToLeftClick}>
                                    {"<--"}
                                </Button>
                                <Button color="primary" onClick={handleToRightClick}>
                                    {"-->"}
                                </Button>
                                <br/>
                                <br/>
                                <Button color="primary" onClick={handleToLeftClickCountries}>
                                    {"<--"}
                                </Button>
                                <Button color="primary" onClick={handleToRightClickCountries}>
                                    {"-->"}
                                </Button>
                            </GridItem>
                            <GridItem xs={3}>
                                <FormControl className={classes.formControl} style={{minWidth: 170}}>
                                    <InputLabel
                                        shrink
                                        htmlFor="select-multiple-native1"
                                    >
                                        Available locations
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        multiple
                                        native
                                        onChange={handleChangeLocations}
                                        inputProps={{
                                            id: "select-multiple-native1",
                                        }}
                                    >
                                        {flocations.map((location) => (
                                            <option
                                                key={location.id}
                                                value={location.id}
                                            >
                                                {location.name}
                                            </option>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl className={classes.formControl} style={{minWidth: 170}}>
                                    <InputLabel
                                        shrink
                                        htmlFor="select-multiple-native1"
                                    >
                                        Available countries
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        multiple
                                        native
                                        onChange={handleChangeCountries}
                                        inputProps={{
                                            id: "select-multiple-native1",
                                        }}
                                    >
                                        {fcountries.map((country) => (
                                            <option
                                                key={country}
                                                value={country}
                                            >
                                                {country}
                                            </option>
                                        ))}
                                    </Select>
                                </FormControl>
                            </GridItem>

                        
                    </GridContainer>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button color="primary" type="submit" disabled={submitDisabled}>
                        Save
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}
