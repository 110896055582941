import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Search from "@material-ui/icons/Search";
import Button from "components/CustomButtons/Button.js";
import searchStyles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import * as api from "api";
import * as errorUtil from "util/errorUtil"

import { CSVLink } from "react-csv";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#ffffff",
        },
    },
    cardTitleWhite: {
        color: "#ffffff",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    textButton: {
        '&:hover': {
            color: "#F47B16",
        },
        background: "none !important",
        border: "none",
        padding: "0 !important",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        fontWeight: "bold",
        cursor: "pointer",
        
    },
    textButton2: {
        '&:hover': {
            color: "#F47B16",
        },
        color: "rgba(0, 0, 0, 0.87)",
        background: "none !important",
        border: "none",
        padding: "0 !important",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        fontWeight: "300",
        cursor: "pointer",
        
    },
};

const mergedStyles = Object.assign({}, styles, searchStyles);
const useStyles = makeStyles(mergedStyles);

function OverviewTable(props) {
    const classes = useStyles();

    const [searchValue, setSearchValue] = React.useState("");
    const [searchClicked, setSearchClicked] = React.useState(false);

    const prettyLink  = {
        backgroundColor: '#8dc63f',
        fontSize: 14,
        fontWeight: 500,
        height: 52,
        padding: '0 48px',
        borderRadius: 5,
        color: '#fff'
      };

    const renderTable = () => {
        if(props.tableData) {
            return <div>
                <br/>
                <CSVLink style={prettyLink} filename={'ItemEvents_' + searchValue} headers={props.tableHeader} data={props.csvData}>Export CSV</CSVLink>
                <Table
                    tableHeaderColor="primary"
                    tableHead={props.tableHeader}
                    tableData={props.tableData}
                />
            </div>;
        } else {
            return searchClicked? 'Loading...': '';
        }
    }

    return (
        <Card>
            <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Item Events</h4>
                <p className={classes.cardCategoryWhite}>
                    Search on serial number to retrieve all the events for that item.
                </p>
                
            </CardHeader>
            <CardBody>
                
            <div className={classes.searchWrapper}>
                <CustomInput
                formControlProps={{
                    className: classes.margin + " " + classes.search
                }}
                inputProps={{
                    placeholder: "Search serial number",
                    inputProps: {
                        "aria-label": "Search",
                        onChange: (event) => {
                            setSearchValue(event.target.value);
                        }
                    }
                }}
                />
                <Button color="white" aria-label="edit" justIcon round onClick={(event) => {
                    props.onSearchClick(searchValue);
                    setSearchClicked(true);
                    }}>
                    <Search />
                </Button>
            </div>
            {renderTable()}
            </CardBody>
        </Card>
    );
}

function formatSerialNumbers(serialNumbers) {
    if(!serialNumbers) {
        return '';
    }

    return (<div>
        {
        serialNumbers.map( (val, index) => {
            return (<div key={index}>{val}</div>);
        })
        }
    </div>)
}

export default class ItemEventSearch extends React.Component {
    

    constructor() {
        super();
        const tableHeader = ["Date", "Type", "Product", "Serial Numbers", "Source", "Destination", "Quantity"];
        this.state = {
            tableHeader: tableHeader,
            csvData: []
        }
    }

    getItemEvents = async (serialNumber) => {
        if(!serialNumber) {
            this.setState({ 
                tableData: [],
             });

             return;
        }

        var result = [];
        try {
            result = await api.getItemEvents(serialNumber);
        } catch (error) {
            errorUtil.handleApiError(error);
        }
        console.log(result);
        const processedResult = [];
        const processedResult2 = [];

        result.forEach(event => {
            const eventTime = new Date(event.date);                
            const processed = [
                eventTime.toLocaleString(),
                event.type,
                event.productName,
                formatSerialNumbers(event.serialNumbers),
                event.inventoryStorageLocationSourceName,
                event.inventoryStorageLocationDestinationName,
                event.quantity
            ];

            const processed2 = [
                eventTime.toLocaleString(),
                event.type,
                event.productName,
                event.serialNumbers.join(' '),
                event.inventoryStorageLocationSourceName,
                event.inventoryStorageLocationDestinationName,
                event.quantity
            ];

            processedResult.push(processed);
            processedResult2.push(processed2);
        });

        this.setState({ 
            tableData: processedResult,
            csvData: processedResult2
         });
    }

    render() {
        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <OverviewTable tableHeader={this.state.tableHeader} tableData={this.state.tableData} csvData={this.state.csvData} onSearchClick={this.getItemEvents} />
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}
