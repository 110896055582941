import React from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@material-ui/core";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import * as api from "api";
import * as errorUtil from "util/errorUtil"


function InventoryTable(props) {

    return (
        <Card>
            <CardBody>
                <Table
                    tableHeaderColor="primary"
                    tableHead={props.tableHeader}
                    tableData={props.tableData}
                />
            </CardBody>
        </Card>
    );
}

export default function LocationInventoryDialog(props) {
    var { open, onClose, 
         location, product
    } = props;

    const tableHeader = ["","Serial Number"];
    const [tableData, setTableData] = React.useState('');

    React.useEffect(() => {
        if(!tableData) {
            retrieveData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const retrieveData = async () => {
        try {
            const result = await api.getInventoryItems(location.id + '_' + product.id);
            result.serialNumbers.sort();
            const serials = result.serialNumbers.map((value, index) => {
                return [index + 1, value];
            });
            setTableData(serials);    
        } catch (error) {
            errorUtil.handleApiError(error);
        }
        
    }

     const close = () => {
        onClose();        
    }


    return (
        <Dialog
            aria-labelledby="simple-dialog-title"
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth={true}
        >
        <DialogTitle>
            { location.name + " Inventory"}
        </DialogTitle>
            <DialogContent>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <div>{"Product: " + product.name}</div>
                        <div>{"Total quantity: " + location.inventory[product.id].quantity}</div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        {tableData? <InventoryTable tableHeader={tableHeader} tableData={tableData} />: <div><p>Loading...</p></div>}
                    </GridItem>
                </GridContainer>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={close}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}
