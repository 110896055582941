const config = {
    env: 'prod', //local, dev, prod
    region: 'europe-west3',
    firebaseConfig: {},
    apiBasePath: ''
}


if(config.env === "prod") {

    config.apiBasePath = "https://europe-west3-invisible-man-275ed.cloudfunctions.net";

    //PRODUCTION CONFIG
    config.firebaseConfig = {
        apiKey: "AIzaSyCrRvqZyht2lBrPI5dl9Legy239vuxUnA4",
        authDomain: "invisible-man-275ed.firebaseapp.com",
        databaseURL: "https://invisible-man-275ed.firebaseio.com",
        projectId: "invisible-man-275ed",
        storageBucket: "invisible-man-275ed.appspot.com",
        messagingSenderId: "365826752545",
        appId: "1:365826752545:web:0cd84c6af4b861f51ea360",
        measurementId: "G-P40WTG27N1"
      };
} else if(config.env === "dev") {

    config.apiBasePath = "https://europe-west3-invisible-man-dev.cloudfunctions.net";

    //DEV CONFIG
    config.firebaseConfig = {
        apiKey: "AIzaSyDMQNddVoaSacSVRczGEIfJbi28QGp7s1s",
        authDomain: "invisible-man-dev.firebaseapp.com",
        databaseURL: "https://invisible-man-dev.firebaseio.com",
        projectId: "invisible-man-dev",
        storageBucket: "invisible-man-dev.appspot.com",
        messagingSenderId: "36555579273",
        appId: "1:36555579273:web:9b070f7e4c8a69e4044b58",
        measurementId: "G-VYLX6PJPPR"
    };    
} else if(config.env === "local") {

    config.apiBasePath = "http://0.0.0.0:5001/invisible-man-dev/europe-west3";

    //LOCAL CONFIG
    config.firebaseConfig = {
        apiKey: "AIzaSyDMQNddVoaSacSVRczGEIfJbi28QGp7s1s",
        authDomain: "invisible-man-dev.firebaseapp.com",
        databaseURL: "https://invisible-man-dev.firebaseio.com",
        projectId: "invisible-man-dev",
        storageBucket: "invisible-man-dev.appspot.com",
        messagingSenderId: "36555579273",
        appId: "1:36555579273:web:9b070f7e4c8a69e4044b58",
        measurementId: "G-VYLX6PJPPR"
    };    
}

export default config;