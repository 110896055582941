import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import ProductSelect from "components/CustomSelect/KeyValueSelect.js";

import * as api from "api";

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0",
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
    },
};

const useStyles = makeStyles(styles);

function UploadButton(props) {
    const classes = useStyles();

    const { filename, onChange } = props;

    return (
        <div>
            <input
                accept=".csv"
                className={classes.input}
                style={{ display: "none" }}
                id="serials"
                multiple
                type="file"
                onChange={(event) => {
                    onChange(event);
                }}
            />

            <label htmlFor="serials">
                <Button
                    color="primary"
                    component="span"
                    className={classes.button}
                >
                    Choose File
                </Button>
                &nbsp;{filename}
            </label>
        </div>
    );
}

function CardHeaderContent() {
    const classes = useStyles();
    return (
        <div>
            <h4 className={classes.cardTitleWhite}>Upload Product Sales</h4>
            <p className={classes.cardCategoryWhite}>
                Upload the Product Sales to deduct them from inventory.
            </p>
        </div>
    );
}

export default class Upload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filename: "No file chosen",
            submitDisabled: true,
            selectedProductId: "",
            products: [],
            file: null
        };
    }

    async componentDidMount() {
        const products = await api.getAllProducts();

        const serializedProducts = [];
        products.forEach((e) => {
            if(e.isSerialised) {
                serializedProducts.push(e);
            }
        });

        this.setState({products: serializedProducts});

    }

    handleSubmit = async (event) => {
        event.preventDefault();
        //const instanceOfFileReader.readAsText(this.state.file);
        this.setState({submitDisabled: true});
        const text = await this.state.file.text()
        const serials = text.split("\n");
        const result = await api.processSales(this.state.selectedProductId, serials);
        alert('Processed('+result.processed.length+'): ' + result.processed.join(', ') + '\nNot processed('+result.notProcessed.length+'): ' + result.notProcessed.join(', '));

        this.setState({
            submitDisabled: true,
            selectedProductId: "",
        });
    };

    onUploadChange = (event) => {
        const newState = {
            filename: "No file chosen",
            file: null,
            submitDisabled: true
        }
        
        if (event.target.files.length > 0) {
            newState.file = event.target.files[0];
            newState.filename = newState.file.name;
            newState.submitDisabled = !(!!this.state.selectedProductId)
        }

        this.setState(newState);
    };

    onSelectChange = (event) => {
        const newState = {
            selectedProductId: event.target.value,
            submitDisabled: !(!!this.state.file)
        }
        this.setState(newState);
    };

    render() {
        const selectProps = {
            onChange: this.onSelectChange,
            value: this.state.selectedProductId
        };

        return (
            <div>
                <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="primary">
                                    <CardHeaderContent />
                                </CardHeader>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={5}>
                                        <ProductSelect labelText="Select product" selectProps={selectProps} keyValueMap={this.state.products}/>
                                        </GridItem>

                                        <UploadButton
                                            filename={this.state.filename}
                                            onChange={this.onUploadChange}
                                        />
                                    </GridContainer>
                                </CardBody>
                                <CardFooter>
                                    <Button color="primary" type="submit" disabled={this.state.submitDisabled}>
                                        Upload
                                    </Button>
                                </CardFooter>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </form>
            </div>
        );
    }
}
