import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
// import App from './App';
import * as serviceWorker from './serviceWorker';

// core components
//import Admin from './layouts/Admin.js';


import "assets/css/material-dashboard-react.css?v=1.9.0";

import SignInScreen from "views/Signin/SignInScreen";




// ========================================



ReactDOM.render(
  <SignInScreen/>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
