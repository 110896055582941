/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import History from "@material-ui/icons/History";
import Person from "@material-ui/icons/Person";
import AceIcon from "assets/jss/icons/AceIcon.js";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import LocationOn from "@material-ui/icons/LocationOn";

import ProductList from "views/Product/ProductList.js";
import LocationList from "views/Location/LocationList.js";
import UserList from "views/User/UserList.js";
import RoleList from "views/Role/RoleList.js";
import UploadProductSales from "views/UploadProductSales/UploadProductSales.js";
import StockOverview from "views/Dashboard/StockOverview.js";
import ItemEventSearch from "views/ItemEvent/ItemEventSearch.js";

// {"customClaims":{
//   "roleId":"CITiHARUDE1nZSe92tL4",
//   "admin":true,
//   "locationsOwned":["X8NwSc8991bncVBjInUR",
//   "P3YJN90KNlX6Hi1tZWsO"]},
//   "id":"V1k8FO8XhRURYffjVF49ksGa6k73",
//   "email":"wang@africancleanenergy.com",
//   "role":{"name":"Warehouse Manager","permissions":["transfer_request_create","transfer_request_accept","transfer_request_get","inventory_add","inventory_deduct","inventory_item_get","location_get","product_get","transfer_request_cancel","admin_stock_overview_get"],"id":"W1ItKVDtu0oD93dA6hB9"}
// }

function isAdminOrIncludesPermission(user, permission) {
  return user.customClaims.admin || user.role.permissions.includes(permission);
}


function getRoutes(user) {
  console.log("getRoutes", user);
  const customClaims = user.customClaims;

  const dashboardRoutes = [];

  console.log("customClaims", customClaims);

  if(isAdminOrIncludesPermission(user, "admin_stock_overview_get")) {
    dashboardRoutes.push(
      {
        path: "/dashboard",
        name: "Stock Overview",
        rtlName: "",
        icon: Dashboard,
        component: StockOverview,
        layout: "/admin"
      }
    );
  }

  if(isAdminOrIncludesPermission(user, "admin_inventory_item_events_get")) {
    dashboardRoutes.push(
      {
        path: "/itemEvents",
        name: "Item Events",
        rtlName: "",
        icon: History,
        component: ItemEventSearch,
        layout: "/admin"
      }
    );
  }

  if(customClaims.admin) {
    const adminRoutes = [
      {
        path: "/users",
        name: "Users",
        rtlName: "",
        icon: Person,
        component: UserList,
        layout: "/admin"
      },
      {
        path: "/roles",
        name: "Roles",
        rtlName: "",
        icon: Person,
        component: RoleList,
        layout: "/admin"
      },
    
      {
        path: "/products",
        name: "Products",
        rtlName: "",
        icon: AceIcon,
        component: ProductList,
        layout: "/admin"
      },
    
      {
        path: "/locations",
        name: "Locations",
        rtlName: "",
        icon: LocationOn,
        component: LocationList,
        layout: "/admin"
      },  
      {
        path: "/uploadProductSales",
        name: "Upload Product Sales",
        rtlName: "",
        icon: CloudUploadIcon,
        component: UploadProductSales,
        layout: "/admin"
      },
    ];

    dashboardRoutes.push(...adminRoutes);
  }

  return dashboardRoutes;
}

export default getRoutes;
