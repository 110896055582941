// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/functions";

import config from "config";

import axios from 'axios';

// Initialize Firebase
firebase.initializeApp(config.firebaseConfig);
firebase.analytics();
const firebaseAuth = firebase.auth();

var functions = firebase.app().functions(config.region);

if(config.env === "local") {
    functions.useEmulator('localhost', 5001);
    firebaseAuth.useEmulator('http://localhost:9099');
}

class InvManApiError extends Error {
    constructor(message, details) {
        super(message);
        this.details = details;
    }
}

async function makeAuthCall(myCall) {
    var idToken;
    try {
        idToken = await firebase.auth().currentUser.getIdToken(false);
    } catch (error) {
        console.log('get ID token failed');
        throw new InvManApiError('permission_denied');
    }

    var response = null;
    
    try {
        response = await myCall(idToken);    
    } catch (error) {
        if (error.response) {
            const data = error.response.data;
            console.log('Error data: ' + JSON.stringify(data));
            var code = data.code? data.code: 'unknown';
            var details = data.details? data.details: null;
            throw new InvManApiError(code, details);
        } else {
            throw new InvManApiError('network_error', null);
        }
        
    }

    return response.data;
   
}

async function getAllProducts() {
    return makeAuthCall(async (idToken) => {
        const axiosConf = {
            method: 'get', 
            url: config.apiBasePath + '/api-admin-v2/products',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + idToken
            }
        };
    
        return axios(axiosConf);
    });
}

async function addProduct(newProduct) {
    return makeAuthCall(async (idToken) => {
        const axiosConf = {
            method: 'post', 
            url: config.apiBasePath + '/api-admin-v2/products',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + idToken
            },
            data: newProduct
        };
    
        return axios(axiosConf);
    });
}

async function updateProduct(product) {
    return makeAuthCall(async (idToken) => {
        const axiosConf = {
            method: 'put', 
            url: config.apiBasePath + '/api-admin-v2/products/' + product.id,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + idToken
            },
            data: product
        };
    
        return axios(axiosConf);
    });
}

async function getAllLocations() {
    return makeAuthCall(async (idToken) => {
        const axiosConf = {
            method: 'get', 
            url: config.apiBasePath + '/api-admin-v2/locations',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + idToken
            }
        };
    
        return axios(axiosConf);
    });
}

async function addLocation(newLocation) {
    return makeAuthCall(async (idToken) => {
        const axiosConf = {
            method: 'post', 
            url: config.apiBasePath + '/api-admin-v2/locations',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + idToken
            },
            data: newLocation
        };
    
        return axios(axiosConf);
    });
}

async function updateLocation(location) {
    return makeAuthCall(async (idToken) => {
        const axiosConf = {
            method: 'put', 
            url: config.apiBasePath + '/api-admin-v2/locations/' + location.id,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + idToken
            },
            data: location
        };
    
        return axios(axiosConf);
    });
}

async function getUsersOverview() {
    return makeAuthCall(async (idToken) => {
        const axiosConf = {
            method: 'get', 
            url: config.apiBasePath + '/api-admin-v2/usersOverview',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + idToken
            }
        };
    
        return axios(axiosConf);
    });
}

async function getAllUsers() {
    return makeAuthCall(async (idToken) => {
        const axiosConf = {
            method: 'get', 
            url: config.apiBasePath + '/api-admin-v2/users',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + idToken
            }
        };
    
        return axios(axiosConf);
    });
}

async function addUser(user) {
    return makeAuthCall(async (idToken) => {
        const axiosConf = {
            method: 'post', 
            url: config.apiBasePath + '/api-admin-v2/users',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + idToken
            },
            data: user
        };
    
        return axios(axiosConf);
    });
}

async function updateUser(user) {
    return makeAuthCall(async (idToken) => {
        const axiosConf = {
            method: 'put', 
            url: config.apiBasePath + '/api-admin-v2/users/' + user.id,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + idToken
            },
            data: user
        };
    
        return axios(axiosConf);
    });
}

async function processSales(productId, serialNumbers) {
    const params = {
        productId: productId,
        serialNumbers: serialNumbers
    }

    return makeAuthCall(async (idToken) => {
        const axiosConf = {
            method: 'post', 
            url: config.apiBasePath + '/api-admin-v2/processSales',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + idToken
            },
            data: params
        };
    
        return axios(axiosConf);
    });
}

async function getAllRolesAndPermissions() {
    return makeAuthCall(async (idToken) => {
        const axiosConf = {
            method: 'get', 
            url: config.apiBasePath + '/api-admin-v2/rolesAndPermissions',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + idToken
            }
        };
    
        return axios(axiosConf);
    });
}

async function addRole(role) {
    return makeAuthCall(async (idToken) => {
        const axiosConf = {
            method: 'post', 
            url: config.apiBasePath + '/api-admin-v2/roles',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + idToken
            },
            data: role
        };
    
        return axios(axiosConf);
    });
}

async function updateRole(role) {
    return makeAuthCall(async (idToken) => {
        const axiosConf = {
            method: 'put', 
            url: config.apiBasePath + '/api-admin-v2/roles/' + role.id,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + idToken
            },
            data: role
        };
    
        return axios(axiosConf);
    });
}

async function getAllPermissions() {
    var remoteGetAll = functions.httpsCallable(
        "admin-permissions-getAll"
    );
    var result = await remoteGetAll();
    // Read result of the Cloud Function.
    var permissions = result.data;

    return permissions;
}

async function getStockOverview() {
    return makeAuthCall(async (idToken) => {
        const axiosConf = {
            method: 'get', 
            url: config.apiBasePath + '/api-admin-v2/stockOverview',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + idToken
            }
        };
    
        return axios(axiosConf);
    });

}

async function getLocationEvents(id, startDateInMs) {
    const params = {
        id: id,
        startDateInMs: startDateInMs
    }

    return makeAuthCall(async (idToken) => {
        const axiosConf = {
            method: 'get', 
            url: config.apiBasePath + '/api-admin-v2/locationEvents',
            params: params,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + idToken
            }
        };
    
        return axios(axiosConf);
    });
}

async function getCurrentUser() {
    return makeAuthCall(async (idToken) => {
        const axiosConf = {
            method: 'get', 
            url: config.apiBasePath + '/api-admin-v2/currentUser',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + idToken
            }
        };
    
        return axios(axiosConf);
    });
}

async function getInventoryItems(id) {
    return makeAuthCall(async (idToken) => {
        const axiosConf = {
            method: 'get', 
            url: config.apiBasePath + '/api-admin-v2/inventoryItems/' + id,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + idToken
            }
        };
    
        return axios(axiosConf);
    });
}

async function getItemEvents(serialNumber) {
    const params = {
        serialNumber: serialNumber
    }

    return makeAuthCall(async (idToken) => {
        const axiosConf = {
            method: 'get', 
            url: config.apiBasePath + '/api-admin-v2/itemEvents',
            params: params,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + idToken
            }
        };
    
        return axios(axiosConf);
    });
}

async function sendPasswordResetEmail(email) {
    const resetUrl = "https://www.googleapis.com/identitytoolkit/v3/relyingparty/getOobConfirmationCode?key=" + config.firebaseConfig.apiKey;
    const requestBody = {
        requestType: "PASSWORD_RESET",
        email: email
    };

    // const requestOptions = {
    //     method: "POST",
    //     headers: { "Content-Type": "application/json" },
    //     body: JSON.stringify(requestBody)
    // };

    const axiosConf = {
        method: 'post', 
        url: resetUrl,
        data: requestBody,
        headers: {
            "Content-Type": "application/json"
        }
    };

    const result = await axios(axiosConf);
    //const result = await fetch(resetUrl, requestOptions);
    return result;
}

export {
    firebase,
    firebaseAuth,
    getAllProducts,
    addProduct,
    updateProduct,
    getAllLocations,
    addLocation,
    updateLocation,
    getUsersOverview,
    getAllUsers,
    addUser,
    updateUser,
    processSales,
    getAllRolesAndPermissions,
    addRole,
    updateRole,
    getAllPermissions,
    getStockOverview,
    getLocationEvents,
    getCurrentUser,
    getInventoryItems,
    getItemEvents,
    sendPasswordResetEmail,
    InvManApiError,
};