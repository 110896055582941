import React from "react";
import acelogo from "assets/img/ace_logo.png";

const imgStyle = {
    float: "left",
    marginRight: "15px",
};

export default function AceIcon(props) {
    return <img src={acelogo} alt="product_icon" style={imgStyle} height="24px" />;
}
